.Financing_Title {
  color: yellow;
  text-align: start;
  font-weight: 500;
  font-size: 32px;
  margin-top: 40px;
}

.Financing_bannerImg {
  /* width: 100%; */
  height: 500px;
  margin-top: 60px;
  object-fit: cover;
}

.Financing_Desing_info {
  text-align: start;
  color: #fff;
  font-size: 15px;
  margin: 10px 0;
}

.Financing_Desing_Title {
  text-align: start;
  color: #fff;
  font-size: 15px;
  margin: 20px 0;
  margin-bottom: 50px;
}

.Financing_Desing_info_Email {
  text-align: start;
  color: #ffff00;
  font-size: 15px;
  margin: 10px 0;
  border-bottom: 1px solid #ffff00;
  width: max-content;
  cursor: pointer;
  margin-bottom: 50px;
}

.Financing_Description {
  margin: auto;
  width: 500px;
  margin-bottom: 200px;
  margin-top: 60px;
}

.Financing_Dec {
  text-align: start;
  color: #fff;
  font-size: 14px;
  margin: 10px 0;
}

.AboutUS_bannerImg {
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  .Financing_Description {
    width: 100%;
  }
}
