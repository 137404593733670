.MachineDetails_Title {
    color: orange;
    font-size: 30px;
    text-align: start;
}

.MachineDetails_Box {
    text-align: start;
    margin-top: 20px;
}

.MachineDetails_Img {
    width: 200px;
    height: 200px;
    text-align: start;
    object-fit: cover;
    margin: 20px 0;
}

.Machine_Details_Description_Text {
    margin: 5px 0;
    color: #fff;
}

.Machine_Details_Description_Price {
    display: flex;
}

.Machine_Details_Description_Price_Title {
    color: yellow;
}

.Machine_Details_Description_Price_Dec {
    color: #fff;
    margin-left: 5px;
}

.RelatedImg {
    width: 150px;
    height: 150px;
    text-align: start;
    object-fit: cover;
    margin: 20px;
}

.RelatedImg_Box{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.MachineDetails_Box_LARGER_VIEW_text{
    margin: 20px;
    color:yellow;
    font-size: 20px;
}