.Header_Logo {
  width: 300px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.header_back {
  height: 200px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(0, 0, 0, 0)
  );
  position: absolute;
  top: 100px;
  width: 100%;
}

.border_divider {
  border: 0.3px solid rgb(119, 119, 119);
  margin: 0 10px;
  height: 30px;
  opacity: 0.5;
}

.DashboardLayout_Header_Link {
  color: chocolate;
  font-weight: bold;
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
}

.DashboardLayout_Header_Link_Active {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  margin: 0 10px;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin: 0 10px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e4100;
}

.DashboardLayout_Navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.bannerImg_Box {
  margin-top: 40px;
  /* height: 50vh; */
}

.bannerImg {
  width: 100%;
  height: 50vh;
  /* height: 60vh; */
  object-fit: contain;
  border: 7px solid chocolate;
}

.carousel-root {
  border: 1px solid #d2691e;
  border-radius: 10px;
}

.Dashboard_Box {
  margin-top: 100px;
  margin-bottom: 200px;
}

.Dashboard_Home_Text {
  color: orange;
  font-size: 30px;
  font-weight: bold;
  text-align: start;
  line-height: 2.5rem;
}

.Dashboard_Home_Sub_Text {
  margin-top: 30px;
  color: orange;
  font-size: 15px;
  font-weight: 500;
  text-align: start;
  line-height: 1.5rem;
}

.Dashboard_Home_Sub_Text_Slogan {
  margin-top: 30px;
  color: orange;
  font-size: 15px;
  font-weight: 500;
  text-align: end;
  line-height: 1.5rem;
}

.Dashboard_Item_Title {
  color: orange;
  font-size: 20px;
  border-bottom: 1px solid rgb(150, 150, 150);
  text-align: start;
  padding-bottom: 5px;
  margin-top: 50px;
}

.Dashboard_Item_Box {
  margin: 20px 0;
  padding: 0 20px;
  text-align: start;
}

.Dashboard_Item_Img {
  width: 250px;
  text-align: start;
  object-fit: cover;
}

.Dashboard_Machine_Title {
  text-align: start;
  color: orange;
  border-bottom: 1px solid orange;
  max-width: fit-content;
  font-size: 15px;
  margin-bottom: 5px;
}

.Dashboard_Machine_Description {
  text-align: start;
  font-size: 15px;
  color: #fff;
  margin-bottom: 2px;
}

.Footer_Address_Text {
  color: #fff;
  font-size: 15px;
  text-align: start;
}

.Footer_Address_Desing_info {
  text-align: right;
  color: #fff;
  font-size: 15px;
}

.Footer_Address_Desing_info_highlight {
  text-align: right;
  color: yellow;
  font-size: 15px;
}

.DashboardLayout_Navbar_Mini_Screen {
  display: none;
}

.Mini_screen_Drop_Down {
  background-color: yellow;
}

.DashboardLayout_Header_Link_Mini_screen {
  color: #000;
  font-weight: bold;
  font-size: 14px;
  margin: 10px;
  cursor: pointer;
}

.DashboardLayout_Bottom {
  background-color: rgba(255, 255, 255, 0);
}

@media only screen and (max-width: 760px) {
  .DashboardLayout_Navbar {
    display: none;
  }

  .DashboardLayout_Navbar_Mini_Screen {
    display: block;
  }
}

@media only screen and (max-width: 467px) {
  .bannerImg {
    object-fit: contain;
  }
}

.control-arrow {
  margin: auto !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 20px solid #fff !important;
  border-top: 20px solid transparent !important;
  border-bottom: 20px solid transparent !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 20px solid #fff !important;
  border-top: 20px solid transparent !important;
  border-bottom: 20px solid transparent !important;
}

.control-arrow {
  background: rgba(255, 255, 255, 0) !important;
}
