.Equipment_Machine_Title{
    color: orange;
    font-size: 25px;
    margin-bottom: 15px;
}

.Equipment_Machine_Img{
    width: 150px;
    height: 150px;
    text-align: start;        
    object-fit: cover;
}

.Equipment_Machine_Details_Box{
    display: flex;
}

.Equipment_Machine_Details{
    margin-left: 15px;
    margin-top: 20px;
}