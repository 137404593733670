.Map {
  background-color: rgb(0, 0, 0);
}

.Map_Box {
  display: flex;
  flex-direction: row;
  height: 355px;
}

.Map_Contact {
  width: 50%;
}

.Map_Contact_Box {
  width: 45%;
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 6rem;
}

.Contact_Title_Text {
  color: #edac15;
  font-size: 26px;
  padding-bottom: 15px;
  margin: 15px 0;
  width: 80%;
  border-bottom: 2px solid #fff;
  font-weight: bold;
  text-align: start;
}

.Map_Contact_Title {
  font-size: 16px;
  font-weight: bold;
  width: 30%;
  display: flex;
  align-self: flex-start;
  text-align: left;
}

.Map_Contact_Title_Link {
  color: black;
  text-decoration: none;
}

.Map_Contact_Title_Link:hover {
  color: #edac15;
}

.ContactForm {
  background: #212121;
}

.ContactForm_Box {
  padding: 40px 0px;
}

.ContactForm_Title {
  color: #edac15;
  text-align: center;
  padding: 0 25px;
  font-size: 32px;
  font-weight: bold;
  border-bottom: 2px solid #fff;
}

.FormInput_Title {
  color: #fff;
  font-size: 16px;
  padding: 0 2;
  text-align: center;
}

.FormInput_Title_Span {
  color: #ff0000;
  padding-left: 2;
  font-size: 16px;
}

.FormInput_Error {
  color: #ff0000;
  margin-top: 5px;
  font-weight: bold;
}

.FormInput_Input {
  padding: 0.5rem;
}

.FormInput_Box {
  margin-top: 20px;
}

.ContactForm_Continue_btn {
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
  color: #edac15 !important;
  font-size: 16px;
  border: none;
  font-style: italic;
  font-weight: bold;
  background: rgba(255, 255, 255, 0) !important;
}

.ContactForm_Submit_btn {
  text-transform: uppercase;
  background: #edac15;
  color: #fff;
  padding: 10px 20px;
  margin-top: 10px;
}

.contact_us_page_background {
  margin: 50px 0;
  margin-top: 40px;
  margin-bottom: 200px;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.ml-2 {
  margin-left: 10px;
}

.ContactForm_Message_Box {
  margin: 40px 0;
}

@media screen and (max-width: 768px) {
  .Map_Box {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .Map_Contact {
    width: 100%;
  }

  .Map_Contact_Box {
    width: 100%;
    padding: 0;
    margin: 50px 0;
  }
  .Map_Contact_Title {
    font-size: 14px;
    padding-left: 10px;
    width: 30%;
    display: flex;
    align-self: flex-start;
    text-align: left;
  }
}

.Map_Contact_Sub_Title {
  margin-left: 5px;
  display: flex;
  align-self: flex-start;
  text-align: left;
}

@media screen and (max-width: 425px) {
  .Map_Contact_Box {
    width: 100%;
    padding: 0;
    margin: 50px 0;
  }

  .Map_Contact_Title {
    font-size: 14px;
    padding-left: 10px;
    display: flex;
    align-self: flex-start;
    text-align: left;
  }

  .Map_Contact_Sub_Title {
    font-size: 14px;
    display: flex;
    align-self: flex-start;
    text-align: left;
  }
}
